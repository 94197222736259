<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    class="pt-16"
    v-bind="$attrs"
  >
    <v-list>
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />
        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      expand: false,
      permissions: [],
      sidebarList: [
        {
          title: 'الرئيسية',
          icon: 'fa-home',
          to: '/',
          role: true,
        },
        {
          title: 'الخطط',
          icon: 'fa-money-bill-alt',
          to: '/packages',
          role: true,
        },
        {
          title: 'الاشتراكات',
          icon: 'fa-users',
          to: '/subscriptions',
          role: true,
        },
      ],
    }),
    computed: {
      ...mapState(['barColor'], {
        role: (state) => state.login.userDataPermission,
      }),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        /**
           * ? check parent
           */
        return this.sidebarList.filter(this.fitlerItem)
          /**
           * ? check children
           */
          .map(item => {
            return ({ ...item, children: item.children ? item.children.filter(this.fitlerItem) : undefined })
          })
          /**
           * this is frustrating
           * ? deleting items that had children but they don't have permisson now
           */
          .filter(item => {
            if (Array.isArray(item.children) && item.children.length === 0) {
              return false
            } else {
              return true
            }
          })
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    created () {
    // this.checkLinksRole()
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      fitlerItem (item) {
        if (item.permission) {
          if (Array.isArray(item.permission)) {
            let shouldGo = false
            for (let index = 0; index < item.permission.length; index++) {
              const { read, add, update, delete: remove } = this.can(item.permission[index])
              if (read || add || update || remove) {
                shouldGo = true
                break
              }
            }
            return shouldGo
          } else {
            const { read, add, update, delete: remove } = this.can(item.permission)
            if (read || add || update || remove) {
              return true
            } else {
              return false
            }
          }
        }
        return true
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-navigation-drawer .v-icon.v-icon
    margin: 0 8px !important
    color: #000 !important

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  color: #000 !important
  font-weight: bold

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: 0
    color: #000 !important
    font-weight: bold

  .v-list-group__items
        .v-list-item
          margin-bottom: 0 !important
          margin: 10px 0
          font-size: 0px
        .v-list-item--active
              color: #3772ff !important
              .v-list-item__title
                  font-weight: 650
                  font-size: 16px
  .v-list-group--sub-group
    .v-list-item
      padding: 0
      padding-left: 0 !important
      color: #fff !important
      margin: 10px 0
  .v-list-item--active
    background-color: transparent !important
    border-radius: 15px
    border-color: transparent !important
    color: #000 !important
    font-weight: 900
    .v-icon.v-icon
      color: #3772ff !important
  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
